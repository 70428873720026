// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../../fonts/pinar/Pinar-Black.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face {
  font-family: "PinarBlack !important";
  src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format("truetype");
}

* {
  font-family: 'PinarBlack !important';
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,oCAAoC;EACpC,+DAAmE;AACrE;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":["@font-face {\n  font-family: \"PinarBlack !important\";\n  src: url(\"../../../fonts/pinar/Pinar-Black.ttf\") format(\"truetype\");\n}\n\n* {\n  font-family: 'PinarBlack !important';\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
